import React, {useEffect, useState} from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import {projectFirestore} from "../../firebase/config";
import {useCollectionDataOnce} from "react-firebase-hooks/firestore";
import {LinearProgress} from "@mui/material";
import {Link} from "react-router-dom";

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)`mb-5`;
const TabsControl = tw.div`xl:max-w-[100%] flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer font-bold text-lg rounded-md px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100! rounded-md`}
  }
`;

const TabContent = tw(motion.div)`mt-6 text-lg font-bold flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-[#fff] rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center  relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500 text-[#00042a]`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;


export const tabsBinders = [
  {
    imageSrc:
        "/1.png",
    title: "Tex Nil",
    content: "Country of Origin: Holland",
    // price: "$5.99",
    rating: "5.0",
    reviews: "77",
    url: "/tex",
    'dosage_administration' :
    'Oral administration via drinking water \npoultry , cattle , goat , sheap and swine : 1 ml per 2-4 liters for 3-5 days',
    category: 'Mycotoxins binders',
    'country_of_origin' : 'Holland',
    "product_name": "Tex-Nil",
    "product_name_arabic": "تيكس نيل",
    "description": "Oral solution contain dry yeast cell wall extract of mannanoligosaccharides and beta glucan ,carnitine , organic acids , multi vitamins , liver and kidney support components. ",
    "indications": "- breakdown /detoxification of mycotoxins and bacterial toxins.\n- stimulation of immune system.\n- stimulate microflora in digestive system .\n- acidifier with antibacterial activity. \n- Growth promoter.",
    "uses_arabic": "يستخدم للقضاء على السموم الفطريه والبكتيريه .\nيستخدم كمحفز للجهاز المناعى \nيعمل كمحفز لخملات الجهاز الهضمى \nيعمل كمحمض ومضاد بكتيرى \nمن"


  },

  {
    imageSrc:
        "/mistav.JPG",
    title: "Saccharoben",
    category: 'Mycotoxins binders',
    content: "Country of Origin: Turkey",
    // price: "$5.99",
    rating: "5.0",
    reviews: "23",
    "product_name": 'Saccharoben',
    "product_name_arabic": 'سكاروبن',
    'country_of_origin': 'Turkey',
    "indications": '- Control and elimination of molds, mycotoxins.\n' +
        '- Pellet binding for obtaining the best quality pellet in the pellet feeds.\n' +
        '- Growth promoter.\t\n' +
        '- Supplementry feed for poultry.',
    'dosage_administration' :
        'Poultry:   500 gm per ton of  feed.',
    description: 'Sachharoben is a mycotoxin binder which offers complete solution for mycotoxins that are \n' +
        'commonly present. beside inorganic component , it contains various organic components \n' +
        'which act in biotransformation of certain mycotoxins, prevent oxidative stress, support and \n' +
        'detoxicate liver and kidney function and improve animal natural immune response.',
    url: "/sac"
  },
]
export const vitamins = [{
  imageSrc:
      "/2.png",
  title: "Vitamis ES 150",
  content: "Country of Origin: Turkey",
  // price: "$5.99",
  rating: "5.0",
  reviews: "98",
  category: 'Vitamins & Amino acids',
  url: "/es150",
  "product_name": 'Vitamis ES 150',
  "product_name_arabic": ' فيتاميس اى اس 150',
  'country_of_origin': 'Turkey',
  "indications": '-Vitamis ES 150  is used in cases of Vitamin E and or Selenium deficiency\n' +
      ' -Avian encephalomalacia, exudative diathesis, nutritional muscular dystrophy. \n' +
      ' -Improving productive performance and fertility. \n' +
      '-Non specific immune stimulant.',
  'dosage_administration' :
      '-Poultry : 1ml / 2  liters  of drinking water for 5-7 days.\n' +
      '-Sheep, Goats, Cattle and Calves: 1-5 ml / animal for 3-5 days consequently.',
  description: 'Vitamis ES 150  contains high concentration of  Vitamin  E which is necessary for growth, hormonal activity and also for muscles and nerves.\n' +
      'It also protects Vit A from oxidation. Vit E enhances the action of Selenium, also Selenium deficiency leads to Vit E deficiency.',
},

  {
    imageSrc:
        "/out.png",
    title: "Vitamis C 500 ",
    content: "Country of Origin: Turkey",
    // price: "$5.99",
    rating: "5.0",
    reviews: "63",
    category: 'Vitamins & Amino acids',
    url: "/c500"
    ,
    "product_name": 'Vitamis C 500 ',
    "product_name_arabic": 'فيتاميس سى ',
    'country_of_origin': 'Turkey',
    "indications": '-Decrease heat stress. \n' +
        '-Used in case of vitamin C defeciency.\n' +
        '-Enhance poultry performance and immunity.',
    'dosage_administration' :
        'Poultry : 0.5 gm  / 1 L of drinking water\n',
    description: 'Ascorbic acid, widely known as vtamin C, is an essential nutrient for animals such as poultry. Ascorbic acid in poultry feed improves animal health and thus increases the growth performance of birds.',
  },
  {
    imageSrc:
        "/3.png",
    title: "Vitamis Amino",
    content: "Country of Origin: Turkey",
    // price: "$5.99",
    rating: "5.0",
    category: 'Vitamins & Amino acids',
    reviews: "743",
    url: "/amino",
    "product_name": 'Vitamis Amino',
    "product_name_arabic": 'فيتاميس أمينو',
    'country_of_origin': 'Turkey',
    "indications": '- In case of vitamins and amino acids deficiency in poultry and farm animals.\n' +
        '- Strengthening the immune mechanism.\n' +
        '- increasing the feed conversion rate. \n' +
        '\n' +
        '- Prevention of breeding losses.\n' +
        '\n' +
        '- In nutritional deficiencies seen during bacterial and viral diseases .',
    'dosage_administration' :
        'Poultry: 0.5  ml / 1 L of drinking water\n',
    description: 'Vitamis Amino is a concentrated mixture of wide range of  amino acids and vitamins to compensate the deficiency used for all farm animals and poultry.',
  }


]

export const minerals = [
  {
    imageSrc:
        "/out.png",
    title: "Calphomin ",
    content: "Country of Origin: Turkey",
    // price: "$5.99",
    rating: "5.0",
    reviews: "39",
    category: 'Minerals  & trace elements ',
    url: "/calphomin",
    "product_name": 'Calphomin ',
    "product_name_arabic": 'كالفومين',
    'country_of_origin': 'Turkey',
    "indications": '-Cases of minerals and trace elements deficiencies.\n' +
        '-Calcium, phosphorus, and manganese are important for growth and bone development.\n' +
        '-Copper and zinc are responsible for maintaining feather color.\n' +
        '-Zinc plays an important role in eggshell formation in layers.\n' +
        '- Rickets, milk fever, leg weakness, osteoporosis, poor feathering, egg shell problems , caged- layer fatigue and cannibalism in poultry.\n' +
        '-Improves growth and milk production.',
    'dosage_administration' :
        'Poultry: 1 ml / liter of drinking water \n',
    description: 'Calphomin  is a source of Minerals and trace elements and some important amino acids which are essential components of poultry diets. They play a major role in growth, bone development, eggshell quality, and maintaining feather color.',
  }
]
export const livers = [{

  imageSrc:
      "/out.png",
  title: "Proleav",
  content: "Country of Origin: Turkey",
  // price: "$5.99",
  rating: "5.0",
  reviews: "34",
  category: 'Liver tonics ',
  url: "/proleav",
  "product_name": 'Proleav',
  "product_name_arabic": 'بروليف',
  'country_of_origin': 'Turkey',
  "indications": '-Liver support and diuretic \n' +
      '-Prevent fatty liver syndrome \n' +
      '-As liver stimulant during the mycotoxicosis treatment\n' +
      '-Decease the mortality and sudden death syndrome\n' +
      '-Supportive therapy in case of viral infections.',
  'dosage_administration' :
      '1 ml per 2 liters of drinking water ',
  description: 'Oral solution contain mixture of Essential amino acids as L-carnitine , methionine , sorbitol , betaine , choline chloride and artichoke extract. \n',

},]

export const kidney = [
  {

    imageSrc:
        "/5.png",
    title: "Glomu Super ",
    content: "Country of Origin: Turkey",
    // price: "$5.99",
    rating: "5.0",
    reviews: "113",
    url: "/glomu",
    category: 'Kidney Support & Diuretics',
    "product_name": 'Glomu Super',
    "product_name_arabic": 'جلوميو سوبر',
    'country_of_origin': 'Turkey',
    "indications": '-Glomu super is effective for stimulation of diuresis especially during gumboro disease and for stimulation of hepatic function.\n' +
        '-Glomu super   is used to prevent nephritis and detoxication.',
    'dosage_administration' :
        'Poultry:  1gm / 3 liters of drinking water daily for 2-3 days or 1 kg / ton of feed for 2-3 days.',
    description: 'Glomu Super is a potent diuretic and renal tonic , used in kidney dysfunction cases due to diseases as Gumboro , IB virus renal form or mycotoxins.\n' +
        '- Electrolytes with Vitamins for instant energy,enhance performance and production, remedy for heat and any stress in all farm animals and poultry.',


  }]
export const relief = [
  {

    imageSrc:
        "/9.png",
    title: "PhytoSolve",
    content: "Country of Origin: Turkey",
    // price: "$5.99",
    rating: "5.0",
    reviews: "231",
    category: 'Brochodilators  & respiratory relief',
    url: "/phyto",
    "product_name": "PhytoSolve",
    "product_name_arabic": "فيتوسولف",
    "description": "Oral solution contain high concentration of natural essential oils as  Eucalyptus oil , Menthol oil, peppermint oil and Thyme oil.",
    "indications": "- Eucalyptus oil restores natural activity of the respiratory epithelium and helps to remove the mucous from the bronchial tubes.\n- Menthol has anaesthetic activity and decrease irritation of the mucous membranes .\n- Mint oil is used for treating certain stomach disorders.\n- PhytoSolve  is indicated for strengthening of the respiratory system.\n- PhytoSolve  helps decrease the post vaccinal reactions. \n- PhytoSolve  is very useful as a supportive  treat mycoplasma and repiratory viral infections like( ND,IB,AI).",
    "uses_arabic": "-فيتوسولڨ يستخدم كمقشع يعمل على زياده الافرازات المخاطيه ويقلل من لزوجتها .\n- فيتوسولڨ يستخدم فى حالات التهاب الجهاز التنفسى ويعمل كمحفز لانتاج الاجسام المضاده فى افرازات القنوات الهضميه.\n- فيتوسولڨ يستخدم كعلاج مساعد فى حاله الاصابه بالمايكوبلازما والاصابات الفيروسيه التنفسيه مثل النيوكاسل والانفلونزا والالتهاب الشعبى المعدى \n- فيتوسولڨ يقلل من رد فعل التحصينات التنفسيه .",
    "dosage_administration": "-Drinking water : 1 ml per 2 liters of water. \n-Spraying :            20 ml per liter.  ",
    "country_of_origin": "Turkey"


  }
]

export const agents = [
  {

    imageSrc:
        "/6.png",
    title: "Proticox",
    content: "Country of Origin: Egypt",
    // price: "$5.99",
    rating: "5.0",
    reviews: "98",
    category: 'Anticoccidal agents ',
    url: "/proticox",
    "product_name": 'Proticox',
    "product_name_arabic": 'بروتى كوكس',
    'country_of_origin': 'Egypt',
    "indications": '-proticox  is indicated for the treatment of coccidiosis in broilers caused by Eimeria tenella, E.necatrix, E. acervulina, E.brunetti, E. mitis, and E. maxima. proticox  also improves general health status of birds and improves feed conversion rate.',
    'dosage_administration' :
        '0.5 ml per liter in drinking water for 3-5 days .\n',
    description: 'Diclazuril is a synthetic molecule that belongs to the Benzene Acetonitriles group with a good safety profile. Diclazuril is a potent coccidiocidal drug, highly effective against coccidia in chickens and turkeys.',


  }
]

export const anti =[
  {

    imageSrc:
        "/7.png",
    title: "Plantocin",
    content: "Country of Origin: Egypt",
    // price: "$5.99",
    rating: "5.0",
    reviews: "101",
    url: "/plantocin",
category: 'Anti-Clostridium',


          "product_name": "Plantocin",
          "product_name_arabic": "بلانتوسين",
          "description": "Oral solution contain high concentration of metronidazole, short chain fatty acids (Glycerides of butyric acid) and natural garlic oil.",
          "indications": "- Effective anti colistridial product for control and prevention of Necrotic enteritis.\n- Prevent non specific diarrhea due to anti bacterial activity against (E-coli and salmonella).\n-Good Appetizer and growth promoter.",
          "indication_ar": "- بلانتوسين عباره عن خليط عالى التركيز من الميترونيدازول و الاحماض الدهنيه قصيره السلسله ( تراى بيوترين) ومستخلص الثوم الطبيعي تعمل معا كمضاد للكوليستريديا قوي جدا. \n- علاج الاسهالات الناتجه عن العدوي البكتيريه المعويه. \n- فاتح شهيه قوي ومنشط نمو ",
          "dosage_administration": "0.5 ml per liter of drinking water for 3-5 days.",
          "dosage_administration_ar": "0.5 مللي لكل لتر من ماء الشرب لمدة 3-5 أيام",
          "country_of_origin": "Egypt"


  }
]
export const heat = [
  {

    imageSrc:
        "/fresh.JPEG",
    title: "Fresh start",
    content: "Country of Origin: Egypt",
    // price: "$5.99",
    rating: "5.0",
    reviews: "87",
    url: "/fresh",
    category: 'Heat stress control',
    "product_name": 'Fresh start',
    "product_name_arabic": 'فريش ستارت',
    'country_of_origin': 'Egypt',
    "indications": '-Anti-heat stress  for poultry which  help birds cope with high temperatures and humidity.\n' +
        ' -to reduce the negative effects of heat stress on\n' +
        '-Anti sress in case of all stress conditions especially  heat stress. \n' +
        '-In case of vitamin C defeciency and dehydration.',
    'dosage_administration' :
        'Poultry: 0.5 gm  / 1 L of drinking water\n',
    description: 'Fresh start is a mixture of vitamin C ( ascorbic acid ) ,  electrolytes and menthol which act synergistically  as anti stress for all farm animals and poultry. ',


  }
]

export const tabsAll = {
  All: [
    ...tabsBinders,
    ...vitamins,
    ...minerals,
    ...livers,
    ...kidney,
    ...relief,
    ...agents,
    ...anti,
    ...heat

  ],
  'Mycotoxins Binders' : tabsBinders,
  'Vitamins & Amino acids' : vitamins,
  'Minerals  & trace elements': minerals,
  "Liver tonics ": livers,
  'Kidney Support & Diuretics': kidney,
  'Brochodilators  & respiratory relief': relief,
  'Anticoccidal agents': agents,
  'Anti-Clostridium' : anti,
  'Heat stress control': heat
}

export default ({
  heading = "Checkout the Menu",
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */


  var groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      rv['All'] = xs
      return rv;
    }, {});
  };

  const tabsOrder = {
    'All': 1,
    "Immune Stimulants & Growth Promoters": 2,
    "Mycotoxins binders" : 3,
    "Kidney Support" : 4,
    "Liver Tonics" : 5,
    "Vitamins and Amino acids": 6,
    "Minerals and trace elements" : 7,
    "Anticoccidal agents": 8,
    "Anti-Clostridium": 9,
    "Heat stress control": 10,
  }


  const [values, loading, error] = useCollectionDataOnce(projectFirestore.collection('products').orderBy('order', 'asc'));
  const [tabsKeys, setTabsKeys] = useState(values ? groupBy(values, 'category') : null);
  const [activeTab, setActiveTab] = useState('All');


  useEffect(() => {
    if(values){
      if(tabsKeys) return
      setTabsKeys(groupBy(values, 'category'))
    }
  }, [values])


  const blaP = values?.find((f) => f?.product_name === 'C- MIX 100')






  return (
    <Container>
      <ContentWithPaddingXl>
        <Header>{heading}</Header>
        {loading && <LinearProgress />}
        {error && <p style={{color: 'red'}}> Error {error.message}</p>}
        <TabsControl className={'kosk1'}>
          {tabsKeys && Object.keys(tabsKeys).sort((a, b) => tabsOrder[a.trim()] - tabsOrder[b.trim()]).map((tabName, index) => (
              <TabControl style={{fontSize: '22px', fontWeight: 'bold'}} key={index} active={activeTab === tabName} onClick={() => setActiveTab(tabName)}>
                {tabName}
              </TabControl>
          ))}
        </TabsControl>

        <HeaderRow>

        </HeaderRow>

        {tabsKeys && Object.keys(tabsKeys).map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale:1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale:0.8,
                display: "none",
              }
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >

            {tabKey === 'Vitamins and Amino acids' && <CardContainer key={blaP.name}>

              <Link to={blaP.url} state={{blaP}}>
                <Card className="group" initial="rest" whileHover="hover" animate="rest">
                  <CardImageContainer className={'bg-[150px_150px]'}  style={{backgroundSize: '150px 150px', backgroundRepeat: 'no-repeat'}} imageSrc={blaP.imageSrc}>
                    <div className={'service-icon'}></div>
                    <CardRatingContainer>
                      <CardRating>
                        <StarIcon />
                        {blaP.rating}
                      </CardRating>
                      <CardReview>({blaP.reviews})</CardReview>
                    </CardRatingContainer>
                    <CardHoverOverlay
                        variants={{
                          hover: {
                            opacity: 1,
                            height: "auto"
                          },
                          rest: {
                            opacity: 0,
                            height: 0
                          }
                        }}
                        transition={{ duration: 0.3 }}
                    >
                      <CardButton>View</CardButton>
                    </CardHoverOverlay>
                  </CardImageContainer>
                  <CardText>
                    <CardTitle style={{color: 'rgb(0 10 98)'}}>{blaP.title}</CardTitle>
                    <CardContent>{blaP.content}</CardContent>
                    <CardPrice>{blaP.price}</CardPrice>
                  </CardText>
                </Card>
              </Link>
            </CardContainer>
            }

            {tabsKeys && tabsKeys[tabKey].map((card, index) => (
              <CardContainer key={index}>

                <Link to={card.url} state={{card}}>
                <Card className="group" initial="rest" whileHover="hover" animate="rest">
                  <CardImageContainer className={'bg-[150px_150px]'}  style={{backgroundSize: '150px 150px', backgroundRepeat: 'no-repeat'}} imageSrc={card.imageSrc}>
                    <div className={'service-icon'}></div>
                    <CardRatingContainer>
                      <CardRating>
                        <StarIcon />
                        {card.rating}
                      </CardRating>
                      <CardReview>({card.reviews})</CardReview>
                    </CardRatingContainer>
                    <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: "auto"
                        },
                        rest: {
                          opacity: 0,
                          height: 0
                        }
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <CardButton>View</CardButton>
                    </CardHoverOverlay>
                  </CardImageContainer>
                  <CardText>
                    <CardTitle style={{color: 'rgb(0 10 98)'}}>{card.title}</CardTitle>
                    <CardContent>{card.content}</CardContent>
                    <CardPrice>{card.price}</CardPrice>
                  </CardText>
                </Card>
                </Link>
              </CardContainer>
            ))}
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

/* This function is only there for demo purposes. It populates placeholder cards */
const getRandomCards = () => {
  const cards = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Chicken Chilled",
      content: "Chicken Main Course",
      price: "$5.99",
      rating: "5.0",
      reviews: "13",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1582254465498-6bc70419b607?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Samsa Beef",
      content: "Fried Mexican Beef",
      price: "$3.99",
      rating: "4.5",
      reviews: "34",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1565310022184-f23a884f29da?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Carnet Nachos",
      content: "Chilli Crispy Nachos",
      price: "$3.99",
      rating: "3.9",
      reviews: "26",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1534422298391-e4f8c172dddb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Guacamole Mex",
      content: "Mexican Chilli",
      price: "$3.99",
      rating: "4.2",
      reviews: "95",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1550461716-dbf266b2a8a7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Chillie Cake",
      content: "Deepfried Chicken",
      price: "$2.99",
      rating: "5.0",
      reviews: "61",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1476224203421-9ac39bcb3327??ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Nelli",
      content: "Hamburger & Fries",
      price: "$7.99",
      rating: "4.9",
      reviews: "89",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1455619452474-d2be8b1e70cd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Jalapeno Poppers",
      content: "Crispy Soyabeans",
      price: "$8.99",
      rating: "4.6",
      reviews: "12",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1473093226795-af9932fe5856?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Cajun Chicken",
      content: "Roasted Chicken & Egg",
      price: "$7.99",
      rating: "4.2",
      reviews: "19",
      url: "#"
    }
  ];

  // Shuffle array
  return cards.sort(() => Math.random() - 0.5);
};
