import { createGlobalStyle } from 'styled-components'
import  { globalStyles } from 'twin.macro'

const GlobalStyles = createGlobalStyle(globalStyles, `
   /* Below animations are for modal created using React-Modal */
     .ReactModal__Overlay {
     transition: transform 300ms ease-in-out;
     transition-delay: 100ms;
     transform: scale(0);
   }
   .ReactModal__Overlay--after-open{
     transform: scale(1);
   }
   .ReactModal__Overlay--before-close{
     transform: scale(0);
   }
   .service-icon:before {
    content: "";
    width: 170px;
    height: 170px;
    border-radius: 12px;
    border: 1px solid #16567f;
    position: absolute;
    top: 40px;
    left: 60px;
    z-index: 99;
    transform: rotate(45deg);
    transition: all 0.3s ease 0s;
   
    }
   @media (max-width: 768px) {
   .kosk1{
    width: '50%'
   }
   }
`)

export default GlobalStyles