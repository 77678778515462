import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

const Container = tw.div`relative`;
export const TwoColumn = tw.div`flex sm:flex-wrap md:flex-nowrap flex-col md:flex-row-reverse justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-start`;
export const Column = tw.div`w-full mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 sm:w-[100%] flex-shrink-0 relative xl:mr-10`;
export const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 sm:w-[100%] mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
    tw`border-2 rounded-md shadow-md border-primary-500 border-solid mt-5 sm:w-[90%]`
]);

const DecoratorBlob = tw(
  SvgDotPattern
)`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`;

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Subheading2 = tw(SubheadingBase)`text-center`;
const Heading2 = tw(
    SectionHeading
)`mt-4 font-black text-[rgb(0 10 98)] text-left text-3xl sm:text-4xl lg:text-5xl text-center leading-tight`;

const Heading = tw(
  SectionHeading
)`mt-4 font-black text-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`whitespace-pre-line mt-8 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Features = tw.div`mx-auto md:mx-0 flex flex-col lg:flex-row max-w-xs lg:max-w-none`;
const Feature = tw.div`mt-10 lg:mt-8 flex items-center md:items-start flex-col md:mr-8 last:mr-0`;

const FeatureHeadingContainer = tw.div`flex items-center`;
const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-primary-500 text-center rounded p-2 flex-shrink-0`}
  ${props => [
    props.iconRoundedFull && tw`rounded-full`,
    props.iconFilled && tw`border-0 bg-primary-500 text-gray-100`
  ]}
  svg {
    ${tw`w-5 h-5`}
  }
`;
const FeatureHeading = tw.div`font-bold text-xl mt-8 text-primary-500`;

const FeatureDescription = tw.div`mt-4 text-center md:text-left text-gray-600 leading-relaxed`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-12 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

export default ({
  subheading = "Our Expertise",
    product,
  heading = (
    <>
      Designed & Developed by <span tw="text-primary-500">Professionals.</span>
    </>
  ),
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  primaryButtonText = "Learn More",
  primaryButtonUrl = "https://timerse.com",
  imageSrc = TeamIllustrationSrc,
  buttonRounded = true,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  showDecoratorBlob = false,
  textOnLeft = true,
  features = null,
  iconRoundedFull = true,
  iconFilled = true,
  iconContainerCss = null
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  /*
   * Change the features variable as you like, add or delete objects
   * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
   */
  const defaultFeatures = [
    {
      Icon: BriefcaseIcon,
      title: "Professionalism",
      description: "We have the best professional marketing people across the globe just to work with you.",
      iconContainerCss: tw`bg-teal-300 text-teal-800`
    },
    {
      Icon: MoneyIcon,
      title: "Affordable",
      description: "We promise to offer you the best rate we can - at par with the industry standard.",
      iconContainerCss: tw`bg-red-300 text-red-800`
    }
  ];
  const addDashes = (text, isArabic = false) => {
    const textWithoutDashes = text.replace(/-/g, '').replace(/•/g, '');

    // Add a dash before each new line
    let formattedText = '';
    if(isArabic){
      formattedText = textWithoutDashes.replace(/\n/g, ' -\n');
      return formattedText;
    }else{
      formattedText = textWithoutDashes.replace(/\n/g, '\n-');
    }

    // Add a dash at the beginning if the text doesn't start with a dash
    return formattedText.startsWith('-') ? formattedText : '-' + formattedText;
  }

  if (!features) features = defaultFeatures;

  const fixArabic = (text) => {
    const bla = text.split('-')
    return bla.map((txt, index) => {
      if(index !== bla.length - 1){
        return txt
      }else{
        return null
      }
    })
    return bla
  }

  return (
    <Container>
      <div style={{marginTop: '40px', marginBottom: '-30px'}}>
        <Subheading2>{product.category}{product?.product_name === 'C- MIX 100' ? ' - Vitamins & Amino acids' : ''}</Subheading2>
        <Heading2>{product.product_name + ' ' + product.product_name_arabic}</Heading2>

      </div>

      <TwoColumn>



        <ImageColumn>

          <FeatureHeading>Description</FeatureHeading>

          <Description>{product.description}</Description>

          <Image src={product.imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
          {showDecoratorBlob && <DecoratorBlob />}




        </ImageColumn>

        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>

            {/*<Description>{product.description}</Description>*/}


            <FeatureHeading style={{textAlign: 'right'}}>الإستخدامات</FeatureHeading>
            {fixArabic(product?.usage).map((txt) => {
              return <p lang={'ar'} dir={'rtl'} style={{textAlign: 'right', direction: 'rtl'}}>{txt !== null && ' - ' + txt }</p>
            })}
            {/*<p style={{textAlign: 'right', lineBreak: 'pre-line', whiteSpace: 'pre-line'}}>{fixArabic(product?.usage)}</p>*/}

            <FeatureHeading>Indications</FeatureHeading>
            <Description>{addDashes(product.indications)}</Description>



            <FeatureHeading>Dosage and administration</FeatureHeading>
            <Description>{product?.dosage_administration ?? product?.dosage}</Description>

            <FeatureHeading>Country of origin</FeatureHeading>
            <Description>{product.country_of_origin}</Description>

            <FeatureHeading>Storage</FeatureHeading>

            <Description>Store in cold dry dark place keep away from children, store in closed packing</Description>

            {/*<Features>*/}
            {/*  {features.map((feature, index) => (*/}
            {/*    <Feature key={index}>*/}
            {/*      <FeatureHeadingContainer>*/}
            {/*        <FeatureIconContainer*/}
            {/*          iconFilled={iconFilled}*/}
            {/*          iconRoundedFull={iconRoundedFull}*/}
            {/*          css={feature.iconContainerCss || iconContainerCss}*/}
            {/*        >*/}
            {/*          {<feature.Icon />}*/}
            {/*        </FeatureIconContainer>*/}
            {/*        <FeatureHeading>{feature.title}</FeatureHeading>*/}
            {/*      </FeatureHeadingContainer>*/}
            {/*      <FeatureDescription>{feature.description}</FeatureDescription>*/}
            {/*    </Feature>*/}
            {/*  ))}*/}
            {/*</Features>*/}

            <PrimaryButton buttonRounded={buttonRounded} as="a" href={'/contact'}>
              {primaryButtonText}
            </PrimaryButton>
          </TextContent>
        </TextColumn>

      </TwoColumn>
    </Container>
  );
};
