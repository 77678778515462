import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, { LogoLink, NavLinks, NavLink as NavLinkBase } from "../headers/light.js";
import Carousel from "react-material-ui-carousel";

const StyledHeader = styled(Header)`
  ${tw`justify-between mt-2 mb-2 bg-transparent`}
  ${LogoLink} {
    ${tw`mr-8 pb-0 w-[500px]`}
  }
`;

const NavLink = tw(NavLinkBase)`
  sm:text-3xl sm:mx-6 
text-3xl
`;

const Container = tw.div`relative -mx-8 -mt-8`;
const TwoColumn = tw.div`flex flex-col lg:flex-row bg-gray-100`;
const LeftColumn = tw.div`ml-8 mr-8 xl:pl-10 py-8`;
const RightColumn = styled.div`
  background-image: url("/chicks.jpg");
  ${tw`bg-green-500  sm:block md:hidden bg-cover bg-center xl:mr-24 xl:mt-24 xl:mb-24 h-96 lg:h-auto lg:w-1/2 lg:flex-1`}
`;

const Content = tw.div`mt-24 lg:mt-24 lg:mb-2 flex flex-col sm:items-center lg:items-stretch`;
const Heading = tw.h1`text-3xl sm:text-5xl md:text-6xl lg:text-5xl font-black leading-none`;
const Paragraph = tw.p`max-w-md my-8 lg:my-5 lg:my-8 sm:text-lg lg:text-base xl:text-lg leading-loose`;

const Actions = styled.div`
  ${tw`mb-8 lg:mb-0`}
  .action {
    ${tw`text-center inline-block w-full sm:w-48 py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  }
  .primaryAction {
    ${tw`bg-primary-500 text-gray-100 hover:bg-primary-700`}
  }
  .secondaryAction {
    ${tw`mt-4 sm:mt-0 sm:ml-4 bg-gray-300 text-gray-700 hover:bg-gray-400 hover:text-gray-800`}
  }
`;

var items = [
  {
    name: "/chicks.jpg",
  },
  {
    name: "/chicks1.jpg",
  }, {
  name: 'chicks8.jpeg'
  }
]
export default ({
  navLinks = [
    <NavLinks key={1}>
      <NavLink href="/about">About</NavLink>
      <NavLink href="/products">Products</NavLink>
      <NavLink href="/Contact">Contact</NavLink>
      {/*<NavLink href={'/'}>Home</NavLink>*/}
      {/*<NavLink href="#">Login</NavLink>*/}
    </NavLinks>
  ],
  heading = (
    <>
      Galaxy Pharma
      <wbr />
      <span tw="text-primary-500"> Egypt</span>
    </>
  ),
  description = "The Best Care for your animals and birds in the Galaxy",
  primaryActionUrl = "/contact",
  primaryActionText = "Contact Us",
  secondaryActionUrl = "/products",
  secondaryActionText = "View Products"
}) => {
  return (
    <Container>
      <StyledHeader links={navLinks} collapseBreakpointClass="sm" />



      <TwoColumn>
        <LeftColumn>
          <Content>
            <Heading>{heading}</Heading>
            <Paragraph style={{fontSize: '16px'}}>{description}</Paragraph>


            {/*<img src={'/chicks.jpg'} width={900} style={{margin: '12px auto' , borderRadius: '12px'}} />*/}




          </Content>

        </LeftColumn>

        {/*<RightColumn></RightColumn>*/}



      </TwoColumn>

      <Carousel autoPlay={true} style={{marginTop: '-12px'}}>
        {
          items.map( (item, i) => <img  height={200} style={{objectFit: 'cover', width: '100%'}} key={i} src={item.name} /> )
        }
      </Carousel>
      <Actions style={{marginTop: '12px', display: 'flex', justifyContent: 'center'}}>
        <a href={primaryActionUrl} className="action primaryAction">
          {primaryActionText}
        </a>
        <a href={secondaryActionUrl} className="action secondaryAction">
          {secondaryActionText}
        </a>

      </Actions>
    </Container>
  );
};
