import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/FullWidthWithImage.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColSingleFeatureWithStats.js";
import SliderCard from "components/cards/ThreeColSlider.js";
import TrendingCard from "components/cards/TwoTrendingPreviewCardsWithImage.js";
import Blog from "components/blogs/PopularAndRecentBlogPosts.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import SupportIconImage from "../moral.png";
import ShieldIconImage from "../science.png";
import CustomerLoveIconImage from "../care.png";
import {Subheading} from "../components/misc/Headings";
import chefIconImageSrc from "../images/p1.png";
import celebrationIconImageSrc from "../images/p2.png";
import kosk1 from "../images/p4.png";
import kosk2 from "../images/p6.png";
import shopIconImageSrc from "../images/p3.png";
import tw from "twin.macro";
import BackgroundAsImageWithCenteredContent from "../components/hero/BackgroundAsImageWithCenteredContent";

const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;


export default () => (

  <AnimationRevealPage>
      {/*<BackgroundAsImageWithCenteredContent />*/}
    <Hero />
      <Features
          subheading={<Subheading>Our Values</Subheading>}
          heading="We follow these."
          description="Our Core Values focus the
association to be"
          cards={[
              {
                  imageSrc: SupportIconImage,
                  title: "Ethical",
                  description: "We act with integrity, honesty and respect.\n" +
                      "\n"
              },
              {
                  imageSrc: ShieldIconImage,
                  title: "Science Based",
                  description: "We lead with science,providing trusted and evidence based information ,and promote research to improve health of animals.\n"
              },
              {
                  imageSrc: CustomerLoveIconImage,
                  title: "Member Centric",
                  description: "We are accountable to the needs of our members."
              },
          ]}
          linkText=""
      />
      <MainFeature />


      {/*<SliderCard />*/}
      <Features
          icons={false}
          heading={
              <>
                  Outstanding  <span style={{color: '#1f957f'}}>Partners.</span>
              </>
          }
          cards={[

              {
                  imageSrc: kosk2,
                  title: "Belgium",
                  url: "https://mercordianimalcare.com/"
              },

              // {
              //     imageSrc: chefIconImageSrc,
              //     title: "Netherlands",
              //     // description: "Lorem ipsum donor amet siti ceali placeholder text",
              //     // url: "https://timerse.com"
              // },
              {
                  imageSrc: celebrationIconImageSrc,
                  title: "Turkey",
                  url: "https://ademixvet.com/home"
                  // description: "Lorem ipsum donor amet siti ceali placeholder text",
                  // url: "https://reddit.com"
              },

              {
                  imageSrc: shopIconImageSrc,
                  title: "Belgium",
                  description: "",
                  small: true,
                  url: "https://jlh-animalhealth.com/"
              },


          ]}

          imageContainerCss={tw`p-2!`}
          imageCss={tw`w-20! h-20!`}
      />
    {/*<TrendingCard />*/}
    {/*<Blog />*/}
    {/*<Testimonial textOnLeft={true}/>*/}
    {/*<FAQ />*/}
    <SubscribeNewsLetterForm />
    <Footer />
  </AnimationRevealPage>
);
