import firebase from 'firebase/compat/app';
import 'firebase/compat/storage'; // <-- See the addition here
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/analytics'
import 'firebase/compat/functions'
import 'firebase/compat/app-check'


const firebaseConfig = {
    apiKey: "AIzaSyAU7g6VfRYYk6SkIfZoradXJzhZVGq41Yw",
    authDomain: "galaxypharma.firebaseapp.com",
    projectId: "galaxypharma",
    storageBucket: "galaxypharma.appspot.com",
    messagingSenderId: "810678752671",
    appId: "1:810678752671:web:ed98fc339a9f98fbcc59aa"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const analytics = firebase.analytics();
const functions = firebase.functions();
// firebase.functions().useEmulator("localhost", 5001);
const projectAuthManager = firebase.auth();

// const appCheck = firebase.appCheck();
// // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// // key is the counterpart to the secret key you set in the Firebase console.
// appCheck.activate(
//     '6Lf74sAjAAAAANBxYC-JixNQRZQgBxvRg6WYcONi',
//
//     // Optional argument. If true, the SDK automatically refreshes App Check
//     // tokens as needed.
//     true);

projectAuthManager.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

// projectAuthManager.setPersistence(firebase.auth.Auth.Persistence.NONE);
const timestamp = firebase.firestore.FieldValue.serverTimestamp;
// window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
// 		'size': 'invisible',
// 		'callback': (response) => {
// 		  // reCAPTCHA solved, allow signInWithPhoneNumber.
// 		  onSignInSubmit();
// 		}
// 	  });


export const handleUploadFile = async (file) => {
    return new Promise(function (resolve, reject) {
        var uploadTask = projectStorage.ref()
            .child(`Asysytemuploadlistings/ ${Date.now()} .jpeg`)
            .put(file, {contentType: "image/jpeg"});

        uploadTask.on(
            "state_changed",
            function progress(snapshot) {
                var percentage =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            },
            function error(err) {
                console.log(err);
                reject(err);
            },
            function complete() {
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    console.log("File available at", downloadURL);
                    resolve(downloadURL);
                });
            }
        );
    });
};
export {projectStorage, projectFirestore, projectAuthManager, analytics, functions};

//firebase deploy --only hosting:cextra