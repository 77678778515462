import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import DownloadApp from "components/cta/DownloadApp.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

import chefIconImageSrc from "images/p1.png";
import celebrationIconImageSrc from "images/p2.png";
import shopIconImageSrc from "images/p3.png";
import kosk1 from "images/p4.png";
import kosk2 from "images/p6.png";


export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <Hero
        heading={<>Galaxy Pharma<HighlightedText>Egypt.</HighlightedText></>}
        description="The Best veterinary Pharmaceuticals in the Galaxy"
        imageSrc="/chicks.jpg"
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Contact Us"
        watchVideoButtonText="View Products"
      />
      <MainFeature
        subheading={<Subheading>Established Since 2016</Subheading>}
        heading={
          <>
            We've been Serving for
            <wbr /> <HighlightedText>over 7 years.</HighlightedText>
          </>
        }
        description={
          <Description>
              Specialized in selling and marketing of veterinary pharmaceutical products ,Galaxy Pharma is a distributor for well known different agencies over the world , we are passionate about animal health and recognize the critical importance of serving animal healthy
              <br />
            <br />
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="See our products"
        imageSrc={
         "/original.jpg"
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      <TabGrid
        heading={
          <>
            Checkout our <HighlightedText>Products</HighlightedText>
          </>
        }
      />
      <Features
          icons={false}
        heading={
          <>
            Amazing <HighlightedText>Partners.</HighlightedText>
          </>
        }
        cards={[

          {
            imageSrc: chefIconImageSrc,
            title: "هولندا",
            // description: "Lorem ipsum donor amet siti ceali placeholder text",
            // url: "https://timerse.com"
          },
          {
            imageSrc: celebrationIconImageSrc,
            title: "تركيا",
            // description: "Lorem ipsum donor amet siti ceali placeholder text",
            // url: "https://reddit.com"
          }, {
                imageSrc: kosk1,
                title: "الصين",
            },
            {
                imageSrc: kosk2,
                title: "تركيا",
            },
            {
                imageSrc: shopIconImageSrc,
                title: "بلجيكا",
                description: "",
                url: ""
            },
        ]}

        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      <MainFeature2
        subheading={<Subheading>A Reputed Brand</Subheading>}
        heading={<>Why <HighlightedText>Choose Us ?</HighlightedText></>}
        statistics={[
          {
            key: "Orders",
            value: "94000+",
          },
          {
            key: "Customers",
            value: "11000+"
          },
          {
            key: "Chefs",
            value: "1500+"
          }
        ]}
        primaryButtonText="Order Now"
        primaryButtonUrl="https://order.now.com"
        imageInsideDiv={false}
        imageSrc="https://images.unsplash.com/photo-1414235077428-338989a2e8c0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzNzI2fQ&auto=format&fit=crop&w=768&q=80"
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      {/*<Testimonial*/}
      {/*  subheading=""*/}
      {/*  heading={<>Customers <HighlightedText>Love Us.</HighlightedText></>}*/}
      {/*/>*/}
      {/*<DownloadApp*/}
      {/*  text={<>People around you are ordering delicious meals using the <HighlightedTextInverse>Galaxy Pharma App.</HighlightedTextInverse></>}*/}
      {/*/>*/}
      <Footer />
    </AnimationRevealPage>
  );
}
